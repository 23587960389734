<template>
  <transition>
    <common v-if="!isCreateChat">
      <template v-slot:headers>
        <div style="margin-left: 4.1rem; margin-right: 0"></div>
        <div style="margin-left: 4.1rem; margin-right: auto"></div>
        <div class="title">沟通</div>
        <!-- <div class="img img-notice" @click="toPage('group')" /> -->
        <div class="img img-notice" />
        <div class="img img-contacts" @click="toPage('contacts')"></div>
      </template>
      <div class="communicate">
        <div class="flex-w screen-search">
          <input class="search" placeholder="搜索" v-model="memberKeyword" />
          <div class="screen el-icon-circle-plus-outline" @click="handleAddChat"></div>
        </div>
        <ul v-if="chatList.length > 0" class="full chat-ul">
          <li v-for="item in chatList" class="flex-w chat-li">
            <div class="head-draw head" v-if="item.is === 'chatgroups'">群组</div>
            <img class="head-draw head" v-else-if="item.is === 'users'" :src="item.avatar" :alt="lastName(item.name)" />
            <div class="chat-detail">
              <div class="project">{{ item.name }}{{ item.is === 'chatgroups' ? `(${item.count})` : '' }}</div>
              <span
                class="last-message"
                v-show="groupMsg(item.id, item.is)"
                v-if="groupMsg(item.id, item.is).type === ''"
                v-html="groupMsg(item.id, item.is).msg"
              ></span>
              <span
                class="last-message"
                v-show="groupMsg(item.id, item.is)"
                v-else-if="groupMsg(item.id, item.is).type === 'img'"
                >[图片]</span
              >
              <span
                class="last-message"
                v-show="groupMsg(item.id, item.is)"
                v-else-if="groupMsg(item.id, item.is).type === 'file'"
                >[文件]</span
              >
              <span class="last-message" v-show="groupMsg(item.groupid)" v-else
                >[{{ groupMsg(item.groupid).type }}]</span
              >
            </div>
            <div class="message-time">{{ hmTime(renderTime(groupMsg(item.id, item.is).time)) }}</div>
          </li>
        </ul>
      </div>
    </common>
    <common v-else-if="isCreateChat">
      <template v-slot:headers>
        <div class="title" @click="isCreateChat = false" style="margin-left: 1.45rem; margin-right: auto">取消</div>
        <div style="margin-left: 0; margin-right: auto"></div>
        <div class="title">发起沟通</div>
        <div style="margin-left: 1.45rem; margin-right: auto"></div>
        <div style="margin-right: 1.45rem; margin-left: auto"></div>
      </template>
      <div class="communicate">
        <div class="flex-w screen-search">
          <input class="search" placeholder="搜索" v-model="memberKeyword" />
        </div>
        <div class="flex-w create-group">
          <span>创建群组</span>
          <i class="el-icon-arrow-right"></i>
        </div>
        <ul class="team-ul">
          <li v-for="item in memLis" class="flex-w mem-box">
            <img :src="item.avatar" />
            <span>{{ item.nickname }}</span>
          </li>
        </ul>
      </div>
    </common>
  </transition>
</template>
<script>
import { mapActions } from 'vuex';
import cookie from '@/utils/cookie';
import moment from 'moment';
import { postChatwsTeam } from '@/service/help.js';
export default {
  data() {
    return {
      name: '',
      memberKeyword: '',
      isCreateChat: false,
      team: [],
    };
  },
  mounted() {
    this.name = cookie(document).get('name');
    this.getChatList(true); //获取聊天列表的群名和id，该方法异步执行后才有id
    this.switchFoot(true);
  },
  methods: {
    ...mapActions([
      'sendImgMessage',
      'sendFileMessage',
      'onAgreeInviteGroup',
      'onGetSendMsLis',
      'getHistoryMessage',
      'onUpdataGroupInfo',
    ]),
    toPage(page) {
      this.$router.push('/chat/' + page);
      this.switchFoot(false);
    },
    getChatList(isGetHistory = false) {
      //获取聊天列表
      let obj = { isGetHistory };
      this.onGetSendMsLis(obj); //vuex里面异步方法，
    },
    handleAddChat() {
      this.isCreateChat = true;
      this.getTeam();
    },
    renderTime(time) {
      const nowStr = null;
      const localStr = time ? new Date(time) : nowStr;
      if (localStr === nowStr) {
        return false;
      }
      const localMoment = moment(localStr);
      const localFormat = localMoment.format('MM-DD hh:mm A');
      return localFormat;
    },
    groupMsg(id, is) {
      //最后一条消息
      if (typeof id == 'undefined') {
        return false;
      }
      let data =
        is === 'chatgroups' ? this.$store.state.chat.msgList.group[id] : this.$store.state.chat.msgList.contact[id];
      if (id.indexOf('s-') === 0) {
        // 云平台账号
        const _data = data.sort((a, b) => a.id - b.id);
        return _data[_data.length - 1];
      } else {
        if (typeof data === 'object') {
          return data[data.length - 1];
        } else {
          return false;
        }
      }
    },
    hmTime(time) {
      if (!time) {
        return null;
      } else return time.substr(5, 6);
    },
    //发起沟通
    getTeam() {
      postChatwsTeam({ keyword: '' }).then(res => {
        this.team = res.data.data;
      });
    },
  },
  computed: {
    chatList() {
      return this.$store.state.chat.userList.sentMsLis.filter(v => {
        return v.name.indexOf(this.memberKeyword) >= 0;
      });
    },
    memLis() {
      return this.team.filter(v => {
        return v.nickname.indexOf(this.memberKeyword) >= 0;
      });
    },
  },
  watch: {
    isCreateChat: {
      handler(val) {
        this.switchFoot(!val);
        this.memberKeyword = '';
      },
      immediate: true,
    },
  },
};
</script>
<style lang="less" scope>
.communicate {
  width: 100%;
  height: 100%;
  border-radius: 1rem 1rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  .screen-search {
    flex: 0 0 3.75rem;
    background: #ffffff;
    border-bottom: 0.09rem solid #e8ecef;
    padding: 0.6rem 0;
    .search {
      margin-right: 1.02rem;
      margin-left: 1.37rem;
      // width: 26.37rem;
      width: 100%;
      height: 2.56rem;
      border-radius: 0.17rem;
      // border:1px solid red;
      background: rgb(247, 248, 250) url('~@/assets/image/icon-progress/icon_search@2x.png') no-repeat 11.09rem 0.68rem;
      background-size: 1.2rem 1.19rem;
      padding: 1rem;

      &::placeholder {
        opacity: 0.25;
        text-align: center;
        color: #c2c7d9;
        transform: translateX(0.6rem);
      }

      &:focus {
        &::placeholder {
          opacity: 0;
        }

        background-image: none;
      }
    }

    .screen {
      margin-right: 1.02rem;
      vertical-align: middle;
      width: 1.71rem;
      height: 1.71rem;
      background-size: cover;
      display: inline-block;
      color: #13bfff;
      font-size: 2.2rem;
    }
  }
  .chat-ul {
    overflow: auto;
    .chat-li {
      width: 32rem;
      height: 6.14rem;
      background: #ffffff;
      border-top: 0.09rem solid #e8ecef;
      justify-content: flex-start;
      &:last-child {
        border-bottom: 0.09rem solid #e8ecef;
      }
      .head {
        flex: 0 0 4.1rem;
        height: 4.1rem;
        line-height: 4.1rem;
        margin: 1.02rem;
      }
      .min-font {
        font-size: 1.19rem;
        font-weight: 400;
        color: #8590b3;
      }
      .chat-detail {
        width: 100%;
        height: 100%;
        .project {
          margin-top: 1.02rem;
          margin-bottom: 0.26rem;
          height: 2.13rem;
          font-size: 1.54rem;
          font-weight: 400;
          color: #334681;
        }
        .last-message {
          .min-font;
        }
      }
      .message-time {
        width: 10rem;
        height: 100%;
        // background-color: red;
        padding: 1.02rem;
        text-align: right;
        .min-font;
      }
    }
  }
  .create-group {
    width: 32rem;
    height: 4.69rem;
    background-color: #fff;
    justify-content: space-between;
    span {
      font-size: 1.45rem;
      font-weight: 400;
      margin-left: 1.02rem;
    }
    .el-icon-arrow-right {
      margin-right: 1.37rem;
    }
  }
  .team-ul {
    height: 100%;
    overflow: auto;
    .mem-box {
      width: 32rem;
      height: 4.69rem;
      background-color: #fff;
      margin-top: 0.68rem;
      justify-content: flex-start;
      padding: 1.02rem;
      img {
        @h: 3.07rem;
        width: @h;
        height: @h;
      }
      span {
        font-size: 1.54rem;
        font-weight: 400;
        color: #334681;
        margin-left: 0.68rem;
      }
    }
  }
}
</style>
